<!--
File: DeuEditForm.vue
Description: form for adding/editing a single DEU.
-->
<template>
  <modal v-if="eligible" :title="screenTitle" :form="formName" :errCount="errors.count()"
    :hasUnsavedChanges="hasUnsavedChanges" @close="$emit('close')" @save="validate">

    <template slot='body'>
      <div class="md-layout-item md-small-size-100 md-size-100">
        <RegionsDropdown :class="getClass('fk_region')" :label="$t('road_network.region')" v-model="fk_region"
          data-vv-name="fk_region" v-validate="modelValidations.fk_region" required />

        <div class="md-layout md-small-size-50 md-size-50">
          <div style="display: flex; align-items: center;">
            <span>ДЭУ-</span>
            <md-field :class="getClass('description')">
              <label :for="description">{{ $t('stdCols.name_ru') }}</label>
              <md-input :id="description" v-model="description" type="text" required data-vv-name="description"
                v-validate="modelValidations.description" />
            </md-field>
          </div>
          <span>&nbsp;</span>
          <div style="display: flex; align-items: center;">
            <span>DEU-</span>
            <md-field :class="getClass('description_en')">
              <label :for="description_en">{{ $t('stdCols.name_en') }}</label>
              <md-input :id="description_en" v-model="description_en" type="text" required data-vv-name="description_en"
                v-validate="modelValidations.description_en" style="flex: 1; border-left: none;" />
            </md-field>
          </div>
        </div>

        <md-field :class="getClass('address1')">
          <label :for="address1">{{ $t('stdCols.address') }} (1)</label>
          <md-input :id="address1" v-model="address1" type="text" required data-vv-name="address1"
            v-validate="modelValidations.address1" />
        </md-field>

        <md-field :class="getClass('address2')">
          <label :for="address2">{{ $t('stdCols.address') }} (2)</label>
          <md-input :id="address1" v-model="address2" type="text" data-vv-name="address2" />
        </md-field>

        <md-field :class="getClass('city')">
          <label :for="city">{{ $t('road_network.city') }}</label>
          <md-input :id="city" v-model="city" type="text" required data-vv-name="city"
            v-validate="modelValidations.city" />
        </md-field>
      </div>
    </template>
  </modal>
</template>
<script>
  import { mapActions } from 'vuex'
  import { Modal, RegionsDropdown } from '@/pages/Components'
  import messages from '@/mixins/messagesMixin'
  import permissions from "@/mixins/permissionsMixin"

  export default {
    name: 'deu-edit-form',
    mixins: [messages, permissions],

    data() {
      return {
        formName: 'DeuEditForm',
        eligible: false,

        fk_region: null,
        description: null,
        description_en: null,
        address1: null,
        address2: null,
        city: null,

        initialFormState: null,
        modelValidations: {
          fk_region: { required: true, numeric: true, min_value: 1 },
          description: { required: true, min: 1 },
          description_en: { required: true, min: 1 },
          address1: { required: true, min: 3 },
          //address2: { required: false, min: 3 },
          city: { required: true, min: 3 },
        }
      }
    },

    props: {
      deuId: null
    },

    components: {
      Modal,
      RegionsDropdown
    },

    async mounted() {
      // Check if we are eligible to view the form
      this.eligible = await this.checkIfScreenAllowed()
      if (!this.eligible) {
        this.$emit('close');
        return
      };

      if (this.deuId) {
        // Load data on existing DEU
        const theDeu = await this.loadDeu(this.deuId)
        this.fk_region = theDeu.fk_region
        this.description = theDeu.description
        this.description_en = theDeu.description_en
        this.address1 = theDeu.address1
        this.address2 = theDeu.address2 ?? ''
        this.city = theDeu.city
      }
      this.$nextTick(() => {
        this.initialFormState = this.getCurrentState()
        this.$validator.validateAll()
      })
    },

    methods: {
      ...mapActions({
        loadDeu: 'LOAD_DEU_BY_ID',
        addItem: 'ADD_NEW_DEU',
        editItem: 'UPDATE_DEU',
        highlightRow: 'HIGHLIGHT_ROW'
      }),

      async validate() {
        const isValid = await this.$validator.validateAll()
        if (!isValid) return

        const theItem = this.getCurrentState()

        let errDesc = '';
        let newId;
        const action = !this.deuId ? this.addItem : this.editItem;
        const payload = !this.deuId ? theItem : { id: this.deuId, theItem };
        try {
          const res = await action(payload)
          newId = res?.deu_id;
        } catch (err) {
          errDesc = err.message || this.$t('messages.unknown_error')
        }

        this.$nextTick(() => this.$validator.reset())
        this.$emit('close');
        await this.savedMessage(errDesc, this.$t('road_network.dep'), `${this.$t('road_network.dep')}-${this.description}`)
        this.highlightRow(newId)
      },

      getCurrentState() {
        return {
          fk_region: this.fk_region,
          description: this.description,
          description_en: this.description_en,
          address1: this.address1,
          address2: this.address2,
          city: this.city
        }
      }
    },

    computed: {
      screenTitle() {
        return this.deuId ? this.$t('screen_titles.deu_upd') : this.$t('screen_titles.deu_add')
      },

      hasUnsavedChanges() {
        if (!this.initialFormState) return false

        return this.fk_region !== this.initialFormState.fk_region ||
          this.description !== this.initialFormState.description ||
          this.description_en !== this.initialFormState.description_en ||
          this.address1 !== this.initialFormState.address1 ||
          this.address2 !== this.initialFormState.address2 ||
          this.city !== this.initialFormState.city
      }
    }
  }
</script>