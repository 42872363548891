<!--
File: Deu.vue
Description: show list of DEU's entered in the DB.
-->
<template>
  <base-data-table v-if="eligible" :loading="showSpinner" :pagination="pagination"
    :btnAddAllowed="isBtnAllowed('AddButton')" :from="from" :to="to" :total="total" @add-item="viewItem()">

    <template #header-filters>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <RegionsDropdown v-model="selectedRegion" />
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <search-input v-model="searchQuery" />
      </div>
    </template>

    <template #table-rows>
      <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}" :class="{ blinking: item[idFieldName] === highlightedRow }"
          :id="item[idFieldName] === highlightedRow ? 'highlightedRow' : null">

          <md-table-cell v-for="header in headers" :key="header.value" :md-label="$t(header.text)"
            :md-sort-by="header.sortable ? header.value : null" :md-numeric="header.format === 'number'">
            {{ item[header.value] }}
          </md-table-cell>

          <md-table-cell :md-label="$t('tables.actions')">
            <table-actions :btnEditAllowed="isBtnAllowed('EditButton')" :btnDeleteAllowed="isBtnAllowed('DeleteButton')"
              @edit="viewItem(item[idFieldName])" @delete="deleteItem(item[idFieldName], item.description)" />
          </md-table-cell>
        </md-table-row>
      </md-table>
    </template>

    <template #editing-dialog>
      <EditForm v-if="showDetailsDlg" :deuId="currentId" @close="showDetailsDlg = false" />
    </template>
  </base-data-table>
</template>
<script>
  import { mapState, mapActions } from 'vuex'
  import { RegionsDropdown } from '@/pages/Components'
  import BaseDataTable from "@/pages/Components/BaseDataTable.vue"
  import BaseTableMixin from '@/mixins/BaseTableMixin'
  import SearchInput from "@/pages/Components/SearchInput.vue"
  import TableActions from "@/pages/Components/TableActions.vue"
  import EditForm from './DeuEditForm.vue'


  export default {
    name: 'deus-list',
    mixins: [BaseTableMixin],

    components: {
      RegionsDropdown,
      BaseDataTable,
      SearchInput,
      TableActions,
      EditForm
    },

    data() {
      return {
        // Переопределяем только специфичные для компонента данные
        formName: 'Deu',
        idFieldName: 'deu_id',
        propsToSearch: ['region', 'description', 'city', 'address1'],
        currentSort: 'region',

        historyMapping: {
          selectedRegion: 0,
          currentSort: 'region',
          currentSortOrder: 'asc',
          pagination: this.pagination,
          searchQuery: ''
        }
      }
    },

    methods: {
      ...mapActions({
        loadList: 'LOAD_DEU_LIST',
        delete: 'DELETE_DEU',
      }),

      handleClick(header, item) {
        if (header.onClick && typeof this[header.onClick] === "function") {
          this[header.onClick](item);
        }
      },

    },

    computed: {
      ...mapState({
        deuList: (state) => state.RoadNetwork.deu_list,
      }),

      cachedData() {
        return this.deuList.map(item => ({
          ...item,
          description: `${this.$t('road_network.dep')}-${item.description}`
        }));
      },

      tableData() {
        return this.customSort(this.cachedData, 'description');
      },

      headers() {
        return [
          { text: "road_network.region", value: "region", sortable: true },
          { text: "road_network.dep", value: "description", sortable: true },
          { text: "stdCols.address", value: "address1", sortable: true },
          { text: "road_network.city", value: "city", sortable: true },
        ]
      }
    },

    watch: {
      selectedRegion(newVal) {
        this.payload = { region_id: newVal };
        this.reloadData();
      }
    }
  }
</script>
<style lang="scss" scoped>
@import '@/mixins/BaseTable.scss';
</style>